import { ANALYTICS_TIMESTAMP_FORMAT } from '@app/core/analytics.service';
import { formatDate } from '@app/shared/date-format.pipe';
import { ServiceArea } from '@app/shared/service-area';

import { AppointmentBookingState } from '../appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchMetaData } from '../appointment-search.service';
import { AppointmentType } from '../appointment-type';
import { InventoryFilterOffice } from '../search-results/appointment-filter-container/appointment-location-filter/appointment-location-filter.component';

interface TrackableSearchState {
  remoteAppointmentTypeId?: number;
  onsite_only: boolean;
  is_widget_shown: boolean;
  selected_date: string;
  selected_offices?: string[];
  provider_count?: number;
  inventory_count?: number;
  inventory_ids?: number[];
  widget_provider_count?: number;
  widget_inventory_count?: number;
  widget_inventory_ids?: number[];
  office_ids?: number[];
}

export interface AppointmentSearchParams {
  service_area_id: number;
  office_ids: number[];
  appointment_type_id: number;
  onsite_only: boolean;
  date_start: string;
  date_end: string;
  recommend_remote_results?: boolean;
}

export class AppointmentSearchState {
  searchMetadata: AppointmentSearchMetaData;
  dateStart: Date;
  dateEnd: Date;
  onsiteOnly = false;
  selectedOffices: InventoryFilterOffice[] = [];

  private bookingState: AppointmentBookingState;

  constructor(bookingState: AppointmentBookingState) {
    this.bookingState = bookingState;
  }

  get officeIds(): number[] {
    return this.selectedOffices.length > 0 ? this.selectedOffices.map(o => o.id) : [];
  }

  getBookingSource(): string {
    return this.bookingState?.source;
  }

  get searchParams(): AppointmentSearchParams {
    const appointmentType: AppointmentType = this.bookingState.appointmentType;
    return {
      service_area_id: this.serviceArea.id,
      office_ids: this.officeIds,
      appointment_type_id: appointmentType.id,
      onsite_only: this.onsiteOnly,
      date_start: formatDate(this.dateStart, 'yyyy-MM-dd'),
      date_end: formatDate(this.dateEnd, 'yyyy-MM-dd'),
      recommend_remote_results: true,
    };
  }

  get trackableProperties(): TrackableSearchState {
    const properties: TrackableSearchState = {
      onsite_only: this.onsiteOnly,
      selected_date: formatDate(this.dateStart, ANALYTICS_TIMESTAMP_FORMAT),
      is_widget_shown: false,
    };

    const metadata: AppointmentSearchMetaData = this.searchMetadata;
    if (!!metadata) {
      properties.inventory_count = metadata.inventoryCount;
      properties.inventory_ids = metadata.inventoryIds;
      properties.provider_count = metadata.providerCount;
      properties.remoteAppointmentTypeId = metadata.remoteAppointmentTypeId;
      properties.is_widget_shown = metadata.recommendedRemoteResultsProviderCount > 0;
      properties.widget_provider_count = metadata.recommendedRemoteResultsProviderCount;
      properties.widget_inventory_count = metadata.recommendedRemoteResultsInventoryCount;
      properties.widget_inventory_ids = metadata.recommendedRemoteResultsInventoryIds;
    }

    if (!!this.selectedOffices && this.selectedOffices.length > 0) {
      properties.selected_offices = this.selectedOffices.map(o => o.name);
    }

    return properties;
  }

  get serviceArea(): ServiceArea {
    return this.bookingState.getSelectedServiceArea();
  }

  get appointmentType(): AppointmentType {
    return this.bookingState.appointmentType;
  }

  get appointmentBookingState(): AppointmentBookingState {
    return this.bookingState;
  }
}
